<template>
    <div class="admin-content">
        <h3>입사지원서 양식 관리</h3>

        <div class="flex-wrap right">
            <div class="ml10">
                <div class="btn-wrap">
                    <button type="button" class="btn-box" v-on:click="methods.getForms">조회</button>
                    <button type="button" class="btn-box green" v-on:click="methods.moveDtls('N');">신규</button>
                </div>
            </div>
            <div>
                <div class="select-wrap">
                    <div class="select-box">
                        <select v-model="state.parameter.UseYn">
                            <option value="">전체</option>
                            <option value="Y">사용</option>
                            <option value="N">삭제</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>

        <!-- 총 개수 -->
        <div class="total-box">
            <p>Total <strong>{{ state.dataTable.rowCount }}</strong></p>
        </div>

        <DataTable class="p-datatable-sm" columnResizeMode="fit" scrollHeight="495px"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            :value="state.dataTable.recFormItems" :paginator="true" :rows="recForm.gridTopSize" :resizableColumns="true">
            <template #empty>
                데이터가 없습니다.
            </template>
            <template #loading>
                Loading ...
            </template>
            <Column field="FORM_NO" header="번호" :sortable="true" headerClass="tc" style="width:40px; text-align:center;" />
            <Column field="FORM_NM" header="양식명" :sortable="true" :exportable="true" headerClass="tc">
            <template #body="{data}">
                <p class="a-hover"><a href="javascript:;" @click="eventHandler.dataTable.onRowClick(data)">{{ data.FORM_NM }}</a></p>
            </template>
            </Column>
            <Column field="CREATE_DT" header="등록일자" :sortable="true" headerClass="tc" style="width:210px; text-align:center;" />
            <Column field="UPDATE_DT" header="수정일자" :sortable="true" headerClass="tc" style="width:210px; text-align:center;" />
            <Column field="USE_YN" header="사용여부" headerClass="tc" style="width:110px; text-align:center;" />
            <Column field="FormRead" header="미리보기" headerClass="tc" style="width:110px; text-align:center;">
                <template #body="{ data }">
                    <button type="button" class="btn-box green" @click="eventHandler.onPreviewClick(data.FORM_NO);">미리보기</button>
                </template>
            </Column>
        </DataTable>
    </div>
</template>
<script>
import { reactive } from '@vue/reactivity';
import { onMounted } from '@vue/runtime-core';
import { recForm } from '@/modules/recruit/REC_RC0100R.js';
import { useRoute } from "vue-router";
import { useStore } from 'vuex';
import router from '@/router';
export default {

    setup() {
        // 라우트
        const vrt = useRoute();

        // 스토어
        const store = useStore();

        // state
        var state = reactive({

            dataTable: {

                // 양식 정보
                recFormItems: new Array(),
                // 행 개수
                rowCount: 0
            },

            parameter: {

                // 양식 번호
                FormNo: recForm.formNo === undefined ? null : recForm.formNo,
                // 양식명
                FormNm: "",
                // 사용여부
                UseYn: "Y",
                // 현재 페이지
                CurPage: "1",
                // 목록 크기
                ListSize: recForm.listSize,
            },

            // 메모 팝업
            dialog: {

                header: "평가 메모",

                // 팝업 오픈 여부
                isOpen: false,

                // 메모 신규 여부
                isNew: true
            }
        });

        // 메소드
        const methods = {

            // 채용 입사지원서 양식 조회
            getForms: () => {

                // 스토어 값 변경
                store.commit("setLoadingStatus", true);

                recForm.getRecForms(state.parameter.UseYn, '').then(result => {

                    state.dataTable.recFormItems = result.Table.rows;
                    state.dataTable.rowCount = result.Table1.rows[0]["TotCnt"];

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", false);
                });
            },

            // 신규 등록
            moveDtls: function () {

                var q = vrt.query;
                q["FormNo"] = 0;
                router.push({ name: 'REC_RC0110D', query: q });
            }
        };

        // 이벤트 핸들러
        const eventHandler = {

            dataTable: {

                // 행 클릭 이벤트
                onRowClick: (data) => {

                    var q = vrt.query;
                    q["FormNo"] = data.FORM_NO;
                    router.push({ name: 'REC_RC0110D', query: q });
                }
            },

            // 미리보기 클릭 이벤트
            onPreviewClick: (data) => {

                var q = vrt.query;
                q["FormNo"] = data;
                q["isPreview"] = 'Y'
                router.push({ name: 'REC_RC0120D', query: q });
            }
        };

        onMounted(() => {

            // 채용 입사지원서 양식 목록 초기조회
            methods.getForms();
        });

        return { state, recForm, methods, eventHandler };
    }
}
</script>